import '@fontsource-variable/roboto'; // Defaults to wght axis
import '@fontsource-variable/roboto/wght.css'; // Specify axis
import '@fontsource-variable/roboto/wght-italic.css'; // Specify axis and style

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AppAuth from './AppAuth.tsx';
import AuthProvider from './AuthProvider.tsx';
import ReactLazyLoading from './components/ReactLazyLoading.tsx';
import { APP_NAME } from './const';
import HelmetWrapper from './HelmetWrapper.tsx';
import InvalidPage from './pages/InvalidPage/InvalidPage.tsx';
import { store } from './store.ts';
import theme from './theme.ts';

dayjs.extend(utc);
dayjs.extend(timezone);

/* eslint-disable react-refresh/only-export-components */
const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage.tsx'));
const RestaurantPage = lazy(() => import('./pages/RestaurantPage/RestaurantPage.tsx'));
const RestaurantSignUpPage = lazy(() => import('./pages/Login/RestaurantSignupPage.tsx'));
const VerifyEmailPage = lazy(() => import('./pages/Login/VerifyEmail.tsx'));
const StorePage = lazy(() => import('./pages/StorePage/StorePage.tsx'));
/* eslint-enable react-refresh/only-export-components */

const { VITE_GOOGLE_CLIENT_ID } = import.meta.env;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <GoogleOAuthProvider clientId={VITE_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <HelmetProvider>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <ToastContainer position="bottom-left" />
                <HelmetWrapper title={APP_NAME}>
                  <BrowserRouter>
                    <Routes>
                      {/*
                        Routes that don't need authentication
                      */}
                      <Route
                        path="/"
                        element={
                          <Suspense fallback={<ReactLazyLoading />}>
                            <LandingPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/restaurant-signup"
                        element={
                          <Suspense fallback={<ReactLazyLoading />}>
                            <RestaurantSignUpPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/verify-email"
                        element={
                          <Suspense fallback={<ReactLazyLoading />}>
                            <VerifyEmailPage />
                          </Suspense>
                        }
                      />

                      {/*
                        Protected Routes
                      */}
                      <Route
                        path="/restaurant"
                        element={
                          <AppAuth>
                            <Suspense fallback={<ReactLazyLoading />}>
                              <RestaurantPage />
                            </Suspense>
                          </AppAuth>
                        }
                      />
                      <Route
                        path="/store/*"
                        element={
                          <AppAuth>
                            <Suspense fallback={<ReactLazyLoading />}>
                              <StorePage />
                            </Suspense>
                          </AppAuth>
                        }
                      />

                      <Route path="*" element={<InvalidPage />} />
                    </Routes>
                  </BrowserRouter>
                </HelmetWrapper>
              </ThemeProvider>
            </AuthProvider>
          </HelmetProvider>
        </LocalizationProvider>
      </Provider>
    </GoogleOAuthProvider>
  </StrictMode>
);
