export enum RestaurantPageOptions {
  Dashboard = 'dashboard',
  Schedule = 'schedule',
  Orders = 'orders',
  Financials = 'financials',
  AddBusiness = 'addBusiness',
  BusinessProfile = 'businessProfile',
  UserProfile = 'userProfile',
  Logout = 'logout'
}

export interface SidebarOptions {
  label: string;
  view: RestaurantPageOptions;
  icon: JSX.Element;
  requiresBusiness: boolean;
}

export interface TimeWindow {
  start: string;
  end: string;
  quantity: number;
  enabled: boolean;
  remainingQuantity?: number;
}

export interface DaySchedule {
  morning?: TimeWindow;
  afternoon?: TimeWindow;
  evening?: TimeWindow;
}

export interface RestaurantInfo {
  id: string;
  displayName: string;
  description: string;
  imageUrl: string;
  price: number;
  purchaseWindow: {
    Mon: DaySchedule;
    Tue: DaySchedule;
    Wed: DaySchedule;
    Thu: DaySchedule;
    Fri: DaySchedule;
    Sat: DaySchedule;
    Sun: DaySchedule;
  };
  street: string;
  city: string;
  state: string;
  zipCode: number;
  coordinates: string;
  googlePlaceId: string;
  // these below are only for the restaurant owner
  createdAt?: string;
  ein?: string;
  enabled?: boolean;
  email?: string;
  phoneNumber?: string;
  updatedAt?: string;
  verified?: boolean;
}

export interface GetAllRestaurantsResponse {
  count: number;
  restaurants: RestaurantInfo[];
}

export type Schedule = Record<'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun', DaySchedule>;

export interface RegisterRestaurantRequest {
  displayName: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipCode: number;
  };
  phoneNumber: string;
  ein: string;
  email: string;
  purchaseWindow: {
    Mon: DaySchedule;
    Tue: DaySchedule;
    Wed: DaySchedule;
    Thu: DaySchedule;
    Fri: DaySchedule;
    Sat: DaySchedule;
    Sun: DaySchedule;
  };
  description: string;
  price: number;
}

export interface UpdateRestaurantRequest extends RegisterRestaurantRequest {
  id: string;
}

export interface UploadRestaurantImageRequest {
  restaurantId: string;
  image: File;
}

export interface RestaurantSignUpBusinessData {
  name: string;
  street: string;
  city: string;
  stateLong: string;
  stateShort: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  lat: number;
  lng: number;
  businessEmail?: string;
  flavorBagType?: string;
  flavorBagPrice?: string;
  restaurantDescription?: string;
  restaurantImage?: File | null;
}
