import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darkest?: string; // Add darkest shade
  }
  interface SimplePaletteColorOptions {
    darkest?: string;
  }
}

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#bde7efb3',
      main: '#238295',
      dark: '#175663',
      darkest: '#0C2B31'
    },
    secondary: {
      light: '#FBE8E1',
      main: '#EA916E',
      dark: '#E6784C',
      darkest: '#8D3614'
    },
    tertiary: {
      light: '#FFFFFF',
      main: '#F5F5F5',
      dark: '#CCCCCC',
      darkest: '#292929'
    },
    text: {
      primary: '#212120',
      secondary: '#FFFFFF'
    },
    background: {
      default: '#FFFFFF'
    },
    divider: '#E0E0E0'
  },
  typography: {
    fontFamily: 'Roboto Variable'
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& .MuiTouchRipple-child': {
            backgroundColor: '#238295'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '& .MuiInputLabel-root': {
            color: '#212120'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#3198bf',
          '&.MuiStepIcon-active': {
            color: '#238295'
          },
          '&.MuiStepIcon-completed': {
            color: '#238295'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': undefined
        }
      }
    }
  }
});

export default theme;
