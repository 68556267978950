import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getIdToken } from 'firebase/auth'; // Import getIdToken to retrieve token
import { auth } from './AuthProvider'; // Import auth from your provider
import {
  ApiResponse,
  CreateOrderRequest,
  CreateOrderResponse,
  CustomerOrderResponse,
  GetAllRestaurantsResponse,
  GetOrderRequest,
  RegisterRestaurantRequest,
  RestaurantInfo,
  RestaurantOrderResponse,
  UpdateRestaurantRequest,
  UploadRestaurantImageRequest
} from './types';
import { ApiUser, GetBankOnboarding, RegisterUserRequest, UpdateUserRequest } from './types/user';

const { VITE_SERVICE_HOST } = import.meta.env;

// Define a service using a base URL and expected endpoints
export const service = createApi({
  tagTypes: [
    //n
    'user',
    'restaurant',
    'getAllRestaurants'
  ],
  reducerPath: 'service',
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_SERVICE_HOST,
    prepareHeaders: async (headers) => {
      // Retrieve the token from Firebase
      const currentUser = auth.currentUser;

      if (currentUser) {
        const idToken = await getIdToken(currentUser, /* forceRefresh */ false);
        headers.set('Authorization', `Bearer ${idToken}`);
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    // <ReturnObject, SendObject>
    /*
      User endpoints
    */
    registerUser: builder.mutation<void, RegisterUserRequest>({
      query: (userInfo) => ({
        method: 'POST',
        url: '/user/create',
        body: userInfo
      })
    }),
    updateUser: builder.mutation<ApiResponse<ApiUser>, UpdateUserRequest>({
      invalidatesTags: ['user'],
      query: (userInfo) => ({
        url: '/user/update',
        method: 'POST',
        body: userInfo
      })
    }),
    getCurrentUser: builder.query<ApiResponse<ApiUser>, void>({
      providesTags: ['user'],
      query: () => ({
        url: '/user/',
        method: 'GET'
      })
    }),
    deleteUser: builder.mutation<void, void>({
      query: () => ({
        url: '/user/',
        method: 'DELETE'
      })
    }),
    getBankOnboarding: builder.query<ApiResponse<GetBankOnboarding>, void>({
      query: () => ({
        url: '/user/bank',
        method: 'GET'
      })
    }),
    /*
      Restaurant endpoints
    */
    registerRestaurant: builder.mutation<ApiResponse<RestaurantInfo>, RegisterRestaurantRequest>({
      invalidatesTags: ['restaurant', 'getAllRestaurants'],
      query: (restaurantInfo) => ({
        url: '/restaurant/create',
        method: 'POST',
        body: restaurantInfo
      })
    }),
    deleteRestaurant: builder.query<void, string>({
      query: (restaurantId) => ({
        url: `/restaurant/${restaurantId}`,
        method: 'DELETE'
      })
    }),
    updateRestaurant: builder.mutation<void, UpdateRestaurantRequest>({
      invalidatesTags: ['restaurant', 'getAllRestaurants'],
      query: (restaurantInfo) => ({
        url: `/restaurant/update/${restaurantInfo.id}`,
        method: 'POST',
        body: restaurantInfo
      })
    }),
    getRestaurantById: builder.query<ApiResponse<RestaurantInfo>, string>({
      providesTags: ['restaurant'],
      query: (restaurantId) => ({
        url: `/restaurant/${restaurantId}`,
        method: 'GET'
      })
    }),
    getAllRestaurants: builder.query<ApiResponse<GetAllRestaurantsResponse>, void>({
      providesTags: ['getAllRestaurants'],
      query: () => ({
        url: `/restaurant`,
        method: 'POST'
      })
    }),
    uploadRestaurantImage: builder.mutation<void, UploadRestaurantImageRequest>({
      invalidatesTags: ['getAllRestaurants'],
      query: ({ restaurantId, image }) => {
        const formData = new FormData();
        formData.append('image', image);

        return {
          url: `/restaurant/uploadImage/${restaurantId}`,
          method: 'POST',
          body: formData
        };
      }
    }),
    /*
      Order endpoints
    */
    createOrder: builder.mutation<ApiResponse<CreateOrderResponse>, CreateOrderRequest>({
      query: (orderInfo) => ({
        url: '/order/create',
        method: 'POST',
        body: orderInfo
      })
    }),
    deleteOrder: builder.mutation<void, string>({
      query: (orderId) => ({
        url: `/deleteOrder/${orderId}`,
        method: 'DELETE'
      })
    }),
    getOrder: builder.query<ApiResponse<GetOrderRequest>, string>({
      query: (orderId) => ({
        url: `/order/${orderId}`,
        method: 'GET'
      })
    }),
    getAllOrders: builder.query<ApiResponse<RestaurantOrderResponse | CustomerOrderResponse>, void>({
      query: () => ({
        url: '/order',
        method: 'POST'
      })
    }),
    getIntentStatus: builder.query<{ status: string }, string>({
      query: (paymentIntentId) => ({
        method: 'GET',
        url: `/order/intentStatus/${paymentIntentId}`
      })
    })
  })
});

export const {
  // User
  useRegisterUserMutation,
  useLazyGetCurrentUserQuery,
  useUpdateUserMutation,
  useLazyGetBankOnboardingQuery,
  // Restaurant
  useLazyGetRestaurantByIdQuery,
  useGetAllRestaurantsQuery,
  useLazyGetAllRestaurantsQuery,
  useRegisterRestaurantMutation,
  useUpdateRestaurantMutation,
  useGetRestaurantByIdQuery,
  useUploadRestaurantImageMutation,
  // Order
  useCreateOrderMutation,
  useGetOrderQuery,
  useGetAllOrdersQuery,
  useLazyGetIntentStatusQuery
} = service;
