import { FastfoodRounded, IcecreamRounded, LocalPizzaRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const foodIcons = [
  <FastfoodRounded sx={{ fontSize: 60, color: 'white' }} />,
  <LocalPizzaRounded sx={{ fontSize: 60, color: 'white' }} />,
  <IcecreamRounded sx={{ fontSize: 60, color: 'white' }} />
];

const ReactLazyLoading = () => {
  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % foodIcons.length);
    }, 1200); // Change icon every 1.2s

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
        <motion.div
          key={currentIconIndex}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.5 }}
        >
          {foodIcons[currentIconIndex]}
        </motion.div>

        <Box sx={{ display: 'flex', gap: 1 }}>
          {[0, 1, 2].map((i) => (
            <motion.div
              key={i}
              animate={{ y: [0, -10, 0] }}
              transition={{ duration: 0.6, repeat: Infinity, delay: i * 0.2 }}
              style={{ width: 16, height: 16, backgroundColor: 'white', borderRadius: '50%' }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ReactLazyLoading;
