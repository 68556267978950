import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import sliceReducer from './slices/slice';
import businessSliceReducer from './slices/businessSlice';

import { service } from './service';

export const store = configureStore({
  reducer: {
    // slices
    slice: sliceReducer,
    businessSlice: businessSliceReducer,

    // services
    [service.reducerPath]: service.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([service.middleware])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
