import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useLazyGetCurrentUserQuery } from './service';
import { FlavorSavrUser } from './types';

const { VITE_FIREBASE_API_KEY } = import.meta.env;

// Firebase configuration
const firebaseConfig = {
  apiKey: VITE_FIREBASE_API_KEY,
  authDomain: 'curious-truth-440101-c6.firebaseapp.com',
  projectId: 'curious-truth-440101-c6',
  storageBucket: 'curious-truth-440101-c6.firebasestorage.app',
  messagingSenderId: '487820920968',
  appId: '1:487820920968:web:02347c778d2134bd2e773d',
  measurementId: 'G-TQQT99S7JC'
};

// Initialize Firebase app and auth
const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

// Context to provide Firebase auth state and merged user object
const AuthContext = createContext<{
  auth: typeof auth;
  user: FlavorSavrUser | null; // TODO: fix type
  loading: boolean;
  error: unknown;
  authenticating: boolean;
  triggerLogin: () => void;
  setRegisteringUser: (val: boolean) => void;
  refreshUser: () => Promise<void>;
}>({
  auth,
  user: null,
  loading: true,
  error: null,
  authenticating: false,
  triggerLogin: () => {},
  setRegisteringUser: () => {},
  refreshUser: async () => {}
});

export const useAuth = () => useContext(AuthContext);

export interface AuthProviderProps {
  children: JSX.Element | JSX.Element[];
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<FlavorSavrUser | null>(null); // Allow storing merged user object
  const [loading, setLoading] = useState(true); // this determines wether or not the async function for getCurrentUser is running
  const [error, setError] = useState<unknown>(null);
  const [authenticating, setAuthenticating] = useState(false); // this determines wether or not the user is currently trying to authenticate
  const [registeringUser, setRegisteringUser] = useState(false);

  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  const fetchMergedUser = useCallback(
    async (firebaseUser?: User) => {
      setAuthenticating(true); // Indicate ongoing auth process
      try {
        // console.log(firebaseUser?.accessToken);

        const apiUser = await getCurrentUser();
        if (!apiUser.data) throw new Error('No user found in API');
        const apiUserData = apiUser.data.data;

        setUser((prevUser) => {
          return {
            ...apiUserData,
            email: firebaseUser?.email || apiUserData.email,
            firebaseUser: firebaseUser ?? (prevUser?.firebaseUser as User)
          };
        });
        setError(null);
      } catch (err) {
        console.error('Error fetching merged user:', err);
        setError(err);
      } finally {
        setAuthenticating(false); // Auth process completed
      }
    },
    [getCurrentUser]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser && !registeringUser) {
        fetchMergedUser(firebaseUser);
      } else {
        setUser(null);
        setAuthenticating(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [registeringUser, fetchMergedUser]);

  const triggerLogin = useCallback(() => {
    setAuthenticating(true);
  }, []);

  return (
    <AuthContext.Provider value={{ auth, user, loading, error, triggerLogin, authenticating, setRegisteringUser, refreshUser: fetchMergedUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
