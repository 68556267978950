import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { RestaurantInfo, RestaurantPageOptions } from '../types';

export interface SliceState {
  activeView: RestaurantPageOptions;
  selectedRestaurant: RestaurantInfo | null;
  businessesList: RestaurantInfo[];
}

const initialState: SliceState = {
  activeView: RestaurantPageOptions.Dashboard,
  selectedRestaurant: null,
  businessesList: []
};

export const businessSlice = createSlice({
  name: 'businessSlice',
  initialState,
  reducers: {
    setActiveView: (state, action: PayloadAction<RestaurantPageOptions>) => {
      state.activeView = action.payload;
    },
    setBusinessesList: (state, action: PayloadAction<RestaurantInfo[]>) => {
      state.businessesList = action.payload;
    },
    setSelectedRestaurant: (state, action: PayloadAction<RestaurantInfo | null>) => {
      state.selectedRestaurant = action.payload;
    },
    resetBusinessState: () => initialState
  }
});

const selectActiveView = (store: RootState) => store.businessSlice.activeView;
const selectBusinessesList = (store: RootState) => store.businessSlice.businessesList;
const selectSelectedRestaurantId = (store: RootState) => store.businessSlice.selectedRestaurant;

const restaurantViewSelector = createSelector([selectActiveView], (activeView) => ({
  activeView
}));

const scheduleViewSelector = createSelector([selectBusinessesList, selectSelectedRestaurantId], (businessesList, selectedRestaurant) => ({
  businessesList,
  selectedRestaurant
}));

const orderViewSelector = createSelector([selectBusinessesList, selectSelectedRestaurantId], (businessesList, selectedRestaurant) => ({
  businessesList,
  selectedRestaurant
}));

const scheduleEditViewSelector = createSelector([selectBusinessesList, selectSelectedRestaurantId], (businessesList, selectedRestaurant) => ({
  businessesList,
  selectedRestaurant
}));

const businessProfileEditViewSelector = createSelector([selectBusinessesList, selectSelectedRestaurantId], (businessesList, selectedRestaurant) => ({
  businessesList,
  selectedRestaurant
}));

export const sliceSelectors = {
  // \n
  restaurantViewSelector,
  scheduleViewSelector,
  orderViewSelector,
  scheduleEditViewSelector,
  businessProfileEditViewSelector
};

export const {
  // \n
  setActiveView,
  setSelectedRestaurant,
  setBusinessesList,
  resetBusinessState
} = businessSlice.actions;

export default businessSlice.reducer;
